<div class="app-container">
  <div class="content">
    <app-header [hidden]="!loggedIn" [hasSaleRosterPermission]="hasSaleRosterPermission"></app-header>
    <router-outlet></router-outlet>
  </div>
  <app-modal [@modal] *ngIf="creatingSale">
    <app-add-sale-input [@zoomAnimation] class="modal-content"></app-add-sale-input>
    <div [@zoomAnimation] class="close-modal" (click)="cancelSaleForm()">
      <img src="assets/img/icons/close.svg"/>
    </div>
  </app-modal>
  <app-modal [@modal] *ngIf="editOrg">
    <app-add-sale-input [@zoomAnimation] class="modal-content"></app-add-sale-input>
    <div [@zoomAnimation] class="close-modal" (click)="cancelSaleForm()">
      <img src="assets/img/icons/close.svg"/>
    </div>
  </app-modal>
  <app-modal [@modal] *ngIf="dataFetchLoading || dataFetchHasError">
    <div class="loading-modal modal-content">
      <div *ngIf="dataFetchHasError" class="error-message">
        <h2> Error </h2>
        <p>{{ errorMessage }}</p>
      </div>
      <div *ngIf="!dataFetchHasError">
        <h2 class="has-spinner-below">{{ loadingMessage }}</h2>
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
      </div>
    </div>
    <div [@zoomAnimation] *ngIf="dataFetchHasError" class="close-modal" (click)="dismissModal()">
      <img src="assets/img/icons/close.svg"/>
    </div>
  </app-modal>
 
</div>
