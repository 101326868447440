import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '@environments/environment'
import { Store, select } from '@ngrx/store'
import { AppState } from '@store/store.interface'
import { selectToken } from '@store/selectors'
import { BaseApiService } from '../base-api.service'

@Injectable({
  providedIn: 'root'
})
export class AuthService  extends BaseApiService {

  readonly endpoint = 'auth/loginWithToken'

  token = null
  private loginUrl = `${environment.apiUrl}/${this.endpoint}`

  constructor( private http: HttpClient, private store: Store<AppState>) {
    super()
    this.store.pipe(select(selectToken)).subscribe(token => {
      this.token = token
  })
    //AWS.config.region = this.region;
  }

  signIn() {
    const url = `${environment.ssoUrl}`
    window.location.href = url
  }

  handleAuthentication() {
    const hash = window.location.hash.substr(1)
    const result = hash.split('&').reduce((res, item) => {
      const parts = item.split('=')
      res[parts[0]] = parts[1]
      return res
    }, {} as any)

    if (result.id_token) {
      this.token = result.id_token
    }
  }

  public setToken(token) {
    this.token = token
  }

  public getToken(): string {
    return this.token
  }

  login(): Observable<string> {
    return this.http.post<string>(
      this.loginUrl,
      { locationId: 'ROSTER_CREATOR' },
      this.httpOptions
    )
  }
}
