import { Injectable } from '@angular/core'
import { Actions, createEffect , ofType } from '@ngrx/effects'
import { Router } from '@angular/router'
import { map } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import { AppState } from '../store.interface'

import { SaleDataCleared } from '../actions/sale-data.actions'
import { OrganisationsCleared } from '../actions/organisations.actions'
import { DataFetchReset } from '../actions/data-fetch.actions'
import { SaleFormCleared} from '../actions/sale-form.actions'
import { SaleRoomDataCleared } from '../actions/sale-room-data.actions'
import {
  AuthActionTypes,
  Login,
  Logout,
} from '../actions/auth.actions'
import { environment } from '@environments/environment'

@Injectable()
export class AuthEffects {

  logout$ = createEffect
( () =>  this.actions$.pipe(
    ofType<Logout>(AuthActionTypes.LogoutAction),
    map(() => {
      //this.store.dispatch(new Login({ token: '' }))

      this.store.dispatch(new DataFetchReset())
      this.store.dispatch(new SaleFormCleared())
      this.store.dispatch(new SaleRoomDataCleared())
      this.store.dispatch(new OrganisationsCleared())
      this.store.dispatch(new SaleDataCleared())

      if (window.sessionStorage) {
        window.sessionStorage.clear()
      }

      window.location.href = `${environment.ssoLogoutUrl}`
      //"https://ap-southeast-2wb2z1nhjo.auth.ap-southeast-2.amazoncognito.com/logout?
      // client_id=3d4p3din81rg088bv11mr0b433&logout_uri=https://login.microsoftonline.com/
      // 7046d25f-a228-47f8-a6f1-35f21ef16a35/oauth2/v2.0/logout";
      return new SaleDataCleared()
    }),
  )
  )

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<AppState>) {}
}
