import { Component, OnInit, OnDestroy } from '@angular/core'
import { Store, select } from '@ngrx/store'
import { AppState } from '@store/store.interface'
import { Actions } from '@store/actions'
import { OrganisationsCancel } from '@store/actions/organisations.actions'

import {
  dataFetchHasError,
  dataFetchIsLoading,
  dataFetchLoaded,
  dataFetchErrorMessage,
  isLoggedIn,
  dataFetchMessage
} from '@store/selectors'

import { SaleForm } from '@shared/models/sale-form.model'
import { trigger, style, animate, transition } from '@angular/animations'
import { ActivatedRoute, Router } from '@angular/router'
import { combineLatest, noop, Subscription } from 'rxjs'

import {
  Dismiss
} from '@store/actions/data-fetch.actions'
import { Login } from './store/actions/auth.actions'
import { AuthService } from './shared/services/auth/auth.service'
import { tap } from 'rxjs/operators'

@Component({
  selector: 'app-root',
  animations: [
    trigger(
      'modal', [
        transition(':leave', [
          style({ opacity: 1 }),
          animate('100ms', style({ opacity: 0 }))
        ])
      ]
    ),
    trigger(
      'zoomAnimation', [
        transition(':enter', [
          style({ transform: 'scale(0.875)', opacity: 0 }),
          animate('75ms', style({ transform: 'scale(1)', opacity: 1 }))
        ])
      ]
    )
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'awh-trader-sale-creator'
  creatingSale = false
  saleForm: SaleForm
  dataFetchLoading: boolean
  dataFetchLoaded: boolean
  dataFetchHasError: boolean
  errorMessage: string
  loadingMessage: string
  loggedIn = false
  storeSubscription: Subscription = new Subscription
  saleFormSubscription: Subscription = new Subscription
  editOrg = false
  hasSaleRosterPermission = true

  constructor (private store: Store<AppState>, private router: Router, private activatedRoute: ActivatedRoute, private auth: AuthService) {}

  updateToken( token ) {
    this.auth.setToken(token)
    this.auth
      .login()
      .pipe(
        tap(() => {
          this.store.dispatch(new Login({ token }))
          this.router.navigateByUrl('')
        }),
      )
      .subscribe(() => noop, (error) => {
        this.hasSaleRosterPermission = false
        this.store.dispatch(new Login({ token: "" }))
      }
      )
    this.store.dispatch(new Login({ token: token }))
  }

  ngOnInit () {

    const fragment = window.location.hash

    // Check if the fragment contains the 'id_token' key
    if (fragment && fragment.includes('id_token=')) {
      const params = new URLSearchParams(fragment.substring(1))
      const token = params.get('id_token')
      this.hasSaleRosterPermission = true
      if (token) {
        this.updateToken(token)
      }
    }
    else {
      if (!this.auth.getToken()) {
        this.auth.signIn()
      }
      else {
        const token = this.auth.getToken()
        this.updateToken(token)
      }
    }

    this.saleFormSubscription = this.store.select('saleForm').subscribe(sale => {
      const showSales = ['in-progress', 'submitting', 'error']
      this.creatingSale = showSales.includes(sale.status)
      this.saleForm = sale
    })

    this.store.select('organisations').subscribe(organisations => {
      this.editOrg = (organisations.status === 'in-progress')
    })

    this.storeSubscription = combineLatest(
      this.store.pipe(select(dataFetchHasError)),
      this.store.pipe(select(dataFetchIsLoading)),
      this.store.pipe(select(dataFetchLoaded)),
      this.store.pipe(select(dataFetchErrorMessage)),
      this.store.pipe(select(dataFetchMessage)),
      this.store.pipe(select(isLoggedIn))
    ).subscribe(jointResults => {
      this.dataFetchHasError = jointResults[0]
      this.dataFetchLoading = jointResults[1]
      this.dataFetchLoaded = jointResults[2]
      this.errorMessage = jointResults[3]
      this.loadingMessage = jointResults[4]
      this.loggedIn = jointResults[5]
    })

  }

  cancelSaleForm () {
    this.store.dispatch(new Actions.SaleFormActions.SaleFormCancelSale())
    this.store.dispatch(new OrganisationsCancel())
  }

  dismissModal () {
    this.store.dispatch(new Dismiss())
  }

  ngOnDestroy () {
    this.storeSubscription.unsubscribe()
    this.saleFormSubscription.unsubscribe()
  }
}
