<app-sales-filter *ngIf="(searchVisible$ | async)" (searchEntered)="onSearchEntered()"></app-sales-filter>
<div class="sales-creator-container" *ngIf="token">
  <div *ngIf="!sales.length" class="no-sales-added">
    <div>
      <h2>No sales added</h2>
    </div>
  </div>
  <app-sale *ngFor="let sale of sales" [sale]="sale"></app-sale>
</div>
<div class="sales-creator-container" *ngIf="(!token && isLoggedIn)">
  <div class="no-permissions" style="color: #b9b9b9;">
    <div>
      <h2>You don't have permission to access woolex presale. Please reach to service desk for correspond permission or logout and login with a new account</h2>
    </div>
  </div>
</div>